/*!
 * jQuery Cookie Policy Plugin
 */
(function ($) {
    $.fn.cookiepolicy = function (options) {
        new jQuery.cookiepolicy($(this), options);
        return this;
    };

    $.cookiepolicy = function (options) {
        options = $.extend({
            cookie: 'cookiepolicyinfo',
            info: 'Strona oraz urządzenia mobilne korzystają z plików cookies w celu realizacji usług i zgodnie z <a href="https://www.masterschool.pl/polityka-plikow-cookies">Polityką Plików Cookies.</a> Możesz okreslić warunki przechowywania lub dostępu do plików cookies w Twojej przeglądarce.</a>',
            close: 'Zamknij'
        }, options || {});

        if ($.cookie(options.cookie) != 'true') {
            var wrapper = $('<div/>').addClass('cookiepolicy').appendTo('body');
            $('<span/>').html(options.info).appendTo(wrapper);
            $('<a/>').addClass('button').html(options.close).appendTo(wrapper)
                .on('click', function (e) {
                    e.preventDefault();
                    $.cookie(options.cookie, true);
                    $(this).parents('.cookiepolicy').remove();
                });
        }
    };
})(jQuery);

$(document).ready(function () {
    $.cookiepolicy();
});