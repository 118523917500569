jQuery(document).ready(function ($) {

    if ($(document).width() > 767) {
        $('.dropdown').removeClass("open");
    }
    else if ($(document).width() < 767) {
        $('.dropdown').addClass("open");
    }

    if ($(document).width() > 767 && $(document).width() < 992) {
        $(".dropdown-toggle").attr("data-toggle", "dropdown");
    }

    $(window).resize(function () {
        if ($(document).width() < 767) {
            $('.dropdown').addClass("open");
        }
        else if ($(document).width() > 767) {
            $('.dropdown').removeClass("open");
        }
    });

    var $w = $(window),
        $canvases = $('body');
    var nav = document.querySelector("#nav");

    $w.scroll(function () {
        var d = $w.height() / 2 + $w.scrollTop();
        $canvases.each(function () {
            var $this = $(this),
                ptop = $this.offset().top,
                total = ptop + $this.height();
            if (d < 750) {
                nav.classList.remove("animated", "fadeIn", "navbar-fixed");
            }
            else {
                nav.classList.add("animated", "fadeIn", "navbar-fixed");
            }
            $this.toggleClass('active', ptop < d && total >= d);
        });
    }).scroll(); //trigger the handler when DOM is ready
});